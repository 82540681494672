import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState, useEffect, useRef } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAuth0 } from "@auth0/auth0-react";
import Dialog  from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import DisplaySettingsIcon from '@mui/icons-material/Edit';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import moment from "moment";
import Multiselect from "../components/Multiselect"
import MultiselectCategories from "../components/MultiselectCategories"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ConfirmDialog from '../components/ConfirmDialog';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export default function DataGridDemo() {

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "Actions",
      width:50,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleOpen(params.row)}>
            <DisplaySettingsIcon />
          </IconButton>
        );
      }
    },
    { field: 'id', headerName: 'id', width: 120},
    { field: 'name', headerName: 'name', width: 250},
    { field: 'cookieUrl', headerName: 'cookie url', width: 150},
    { field: 'url', headerName: 'attached url', width: 200},
    {
      field: 'processed', headerName: 'Processed', width: 70, renderCell: (params) => (
        <>{params.row.processed ? (
            <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
            <RemoveCircleIcon style={{ color: 'red' }} />
          )}</>),
    },
    {
      field: 'automaticDelete', headerName: 'Active', width: 70, renderCell: (params) => (
        <>{params.row.automaticDelete ? (
            <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
            <RemoveCircleIcon style={{ color: 'red' }} />
          )}</>),
    },
    {
      field: 'purposes',
      headerName: 'Purposes',
      width: 400,
      renderCell: (params:any) => (
        <div>
        {/* Render your array data in a way that fits your UI */}
        {params.value?.map((value:any) => (
          <Chip key={value.code} label={value.name}></Chip>
        ))}
      </div>
      ),
    },
    { field: 'changedUsername', headerName: 'Changed by', width: 320 },
    { field: 'changed', headerName: 'Changed', width: 220,   type: 'dateTime',  valueFormatter: params => params?.value ? moment(params?.value).format("DD.MM.YYYY HH:mm:ss"):"" },

  ];

  const columnsTracking: GridColDef[] = [
    { field: 'tracked', headerName: 'Tracked', width: 170,   type: 'dateTime',  valueFormatter: params => params?.value ? moment(params?.value).format("DD.MM.YYYY HH:mm:ss"):"" },
    { field: 'decision', headerName: 'Decision', width: 100},
    { field: 'url', headerName: 'Url', width: 150},
    { field: 'value', headerName: 'Value', width: 200},
    { field: 'value64', headerName: 'Value Base64', width: 200},
  ];
   

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);


    const { getAccessTokenSilently } = useAuth0();
    const [tableData, setTableData] = useState([]);
    const [tableCount, setTableCount] = useState(0);
    
    const [trackingTableData, setTrackingTableData] = useState([]);

    const [codebookPurposes, setCodebookPurposes] = useState<{value:string, label:string, category:string}[]>([]);
    const [codebookCategories, setCodebookCategories] = useState<{value:string, label:string, category:string}[]>([]);

    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [processedFilter, setProcessedFilter] = useState('all');
    const [automaticDeleteFilter, setAutomaticDeleteFilter] = useState('all');


    const [item, setItem] = useState(Object);
    const [selectedPurposes, setSelectedPurposes] = useState<string[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    const [tabValue, setTabValue] = React.useState('1');
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

    const inputNameRef = useRef<HTMLInputElement>(null);
    const categoriesRef = useRef<{ clean: () => void }>(null);




    //HANDLE TAB CHANGE ON PARTNER DETAIL DIALOG
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setTabValue(newValue);
    };

    //PRESS ENTER IN SEARCH TEXT BOX
    const handleEnterInUrl = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault(); // prevent form submission
        loadData();
      }
    };

    //LOAD DATA TO COOKIES LIST
    // eslint-disable-next-line
    const loadData = async() => {

        console.log("button clicked ");
        setLoading(true);

        const token = await getAccessTokenSilently();

        const response = await fetch(process.env.REACT_APP_COOKIES_URL +"?url="+url+"&name="+name+"&processedFilter="+processedFilter+"&automaticDeleteFilter="+automaticDeleteFilter, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        if (response.status) {
            const cookies = await response.json();
            if (cookies != null)
            {
              setTableData(cookies.list);
              setTableCount(cookies.totalCount);
            }
            console.log("data loaded.")
          }
          setLoading(false);
    };

    const loadDetail = async(id:string) => {

      console.log("button clicked ");
      const token = await getAccessTokenSilently();
      const response = await fetch(process.env.REACT_APP_COOKIES_URL +"/"+id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.status) {
          const cookie = await response.json();
          if (cookie != null)
          {
            setItem(cookie);
            setSelectedPurposes(cookie.purposes? cookie.purposes.map((obj:any) => obj.code) : []);
            setSelectedCategories(cookie.categories? cookie.categories.map((obj:any) => obj.code) : []);
            setTrackingTableData(cookie.trackingLog? cookie.trackingLog : []);
          }

          console.log("data loaded.")
        }
  };

    //LOAD CODEBOOKS = PURPOSES AND CATEGORES
  const loadCodebooks = async() => {

    const token = await getAccessTokenSilently();

    const response = await fetch(process.env.REACT_APP_CODEBOOK_URL +"?codebookId=C004", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    if (response.status) {
        const codebook = await response.json();
        if (codebook != null)
        {
          var array:any[] = [];
          codebook.items.filter((item:any) => item.active===true).forEach((item:any) => {
            array.push({value:item.code, label:item.name})
          });
          setCodebookCategories(array);
        }
        console.log("data loaded.")
      }
    const response2 = await fetch(process.env.REACT_APP_CODEBOOK_URL +"?codebookId=C002", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    if (response2.status) {
        const codebook2 = await response2.json();
        if (codebook2 != null)
        {
          var array2:any[] = [];
          codebook2.items.filter((item:any) => item.active===true).forEach((item:any) => {
            array2.push({value:item.code, label:item.name, category:item.additionalFields[0]})
          });
          setCodebookPurposes(array2);
        }
        console.log("data loaded.")
      }

};

    //AUTO LOAD AFTER DIALOG INITIALIZATION
    useEffect(() => {
      loadData();
      loadCodebooks();
    // eslint-disable-next-line
    }, [])

        // OPEN PARTNER DIALOG
        const handleOpen = async (props:any) => {
          //console.log(props0);
          if (props.id !== 'new')
            await loadDetail(props.id);
          else
            setItem(props)
          console.log(props)
          setOpen(true);

        };
    
        //CLOSE PARTNER DIALOG
        const handleClose = () => {
          setOpen(false);
        };
    
        //SAVE COOKIE DIALOG
        const handleSave = async() => {
          
          const itemToSave = item;
          itemToSave.purposes = selectedPurposes;
          itemToSave.categories = selectedCategories;

          console.log(itemToSave);
    
          const token = await getAccessTokenSilently();
      
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': `Bearer ` + token },
            body: JSON.stringify(itemToSave)
            };
          await fetch(process.env.REACT_APP_COOKIE_URL, requestOptions);
          
          setOpen(false);
          loadData();
        }

        //SAVE AND NEW COOKIE
        const handleSaveAndNew = async() => {
          await handleSave();
          newCookie();
        }

        //NEW COOKIE
        const newCookie = async() =>
        {
          setSelectedPurposes([]);
          setSelectedCategories([]);
          setTrackingTableData([]);
          if (categoriesRef.current) {
            categoriesRef.current.clean();
          }
          var cookie = {id:'new', name:'',url:[], cookieUrl:url,excludeList:'',automaticDelete:false, procesed:false,purposes:[],categories:[],internalDescription:'',publicDescription:''};
          handleOpen(cookie);

        }

        //DELETE COOKIE
        const handleDelete = async() => {
          
  
          const token = await getAccessTokenSilently();
      
          const requestOptions = {
            method: 'DELETE',
            headers: { 
                        'Authorization': `Bearer ` + token }
            };
          await fetch(process.env.REACT_APP_COOKIE_URL + '?id=' + item.id, requestOptions);
          
          loadData();
          setOpen(false);
          setIsConfirmDialogOpen(false);
        }   

  return (
    <Box sx={{ height: `auto`, width: '100%' }}>
      <Box
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1, width: '1000' },
            }}
            noValidate
            autoComplete="off"
          >
                <div  style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <Button variant="contained" onClick={newCookie} sx={{pr:2}}>New cookie</Button>
                <TextField label="Search in name" id="txtSearch" value={name} onChange={(event:any) => setName(event.target.value)} onKeyDown={handleEnterInUrl} inputProps={{ style: { width: "300px" }}} />
                <TextField label="Search in url" id="txtSearch" value={url} onChange={(event:any) => setUrl(event.target.value)} onKeyDown={handleEnterInUrl} inputProps={{ style: { width: "300px" }}} />
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label">Filter processed</InputLabel>
                  <Select
                    id="demo-simple-select"
                    label="Filter processed"
                    value={processedFilter}
                    onChange={(event: SelectChangeEvent) => { setProcessedFilter(event.target.value as string); }}
                    style={{ minWidth: "170px",padding:"5px" }}
                  >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'notProcessed'}>Not processed</MenuItem>
                    <MenuItem value={'processed'}>Processed</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label">Filter aut. delete</InputLabel>
                  <Select
                    id="demo-simple-select"
                    label="Filter automatic delete"
                    value={automaticDeleteFilter}
                    onChange={(event: SelectChangeEvent) => { setAutomaticDeleteFilter(event.target.value as string); }}
                    style={{ minWidth: "170px", paddingLeft:"5px" ,padding:"5px" }}
                  >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'automaticDelete'}>Automatic delete</MenuItem>
                    <MenuItem value={'notAutomaticDelete'}>Not automatic delete</MenuItem>
                  </Select>
                </FormControl>
                <Button variant="contained" onClick={loadData}>Search</Button>
            </div> 
            <Box sx={{ width: '100%' }}>
                { loading ? 
                  <LinearProgress key='waitingStatus'  />
                : null}
            </Box>
      </Box>
      <DataGrid
        rows={tableData}
        rowCount={tableCount}
        columns={columns}
        pageSize={13}
        rowsPerPageOptions={[13]}
        disableSelectionOnClick
        autoHeight={true}
        experimentalFeatures={{ newEditingApi: true }}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{
          sx: {
            width: 1700,
            maxHeight: 900
          }
        }}
      >
      <DialogTitle sx={{height:90}}>Cookie {item.id}</DialogTitle>
        <DialogContent dividers style={{height:'900px'}}>
        <Chip
          label={'Created by: ' +item.createdUsername + ' on: ' + moment(item.created).format("DD.MM.YYYY HH:mm:ss")}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10
          }}
        ></Chip>
        <Chip
          label={'Changed by:: ' +item.changedUsername + ' on: ' + moment(item.changed).format("DD.MM.YYYY HH:mm:ss")}
          sx={{
            position: 'absolute',
            right: 8,
            top: 50
          }}></Chip>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Cookie" value="1" />
              <Tab label="Tracking log" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
          <Grid container spacing={2} columns={2}>
            <Grid item xs={1}>
              <TextField
                        sx={{ m: 1}}
                        ref={inputNameRef}
                        label="Name"
                        placeholder="Name"
                        value={item.name}
                        fullWidth 
                        onChange={(event:any) => setItem((prevState:any) => ({
                                                      ...prevState,
                                                        name: event.target.value,
                                                    }))}
                    />
            </Grid>
            <Grid item xs={1}>
            <TextField
                        sx={{ m: 1}}
                        label="Cookie url"
                        placeholder="Cookie url"
                        value={item.cookieUrl}
                        fullWidth 
                        onChange={(event:any) => setItem((prevState:any) => ({
                                                      ...prevState,
                                                      cookieUrl: event.target.value,
                                                    }))}
                    />
            </Grid>
          </Grid>

         <TagsInput value={item.url} inputProps={{ placeholder:"Enter attached url"}} onChange={(tags:any) => setItem((prevState:any) => ({
                                                  ...prevState,
                                                    url: tags,
                                                }))}
                                                />
        <TextField
                    sx={{ m: 1}}
                    label="Exclude list"
                    placeholder="Exclude list"
                    value={item.excludeList}
                    fullWidth 
                    onChange={(event:any) => setItem((prevState:any) => ({
                                                  ...prevState,
                                                    excludeList: event.target.value,
                                                }))}
                />
       
        <Grid container spacing={2} columns={2}>
          <Grid item xs={1}>
            <FormControlLabel control={<Checkbox checked={item.automaticDelete}                     
                                      onChange={(event:React.ChangeEvent<HTMLInputElement>) => setItem((prevState:any) => ({
                                                    ...prevState,
                                                    automaticDelete: event.target.checked,
                                                }))} />} label="Automatic delete" sx={{pl:1,height:60}} />
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel control={<Checkbox checked={item.processed}                     
                                      onChange={(event:React.ChangeEvent<HTMLInputElement>) => setItem((prevState:any) => ({
                                                    ...prevState,
                                                    processed: event.target.checked,
                                                }))} />} label="Processed" sx={{pl:1,height:60}} />
          </Grid>
        </Grid>


        <MultiselectCategories label="Purposes" options={codebookPurposes} selected={selectedPurposes} onChange={(selectedItems: string[]) => { setSelectedPurposes(selectedItems); }} ref={categoriesRef}></MultiselectCategories>
        <Multiselect label="Categories" options={codebookCategories} selected={selectedCategories} onChange={(selectedItems: string[]) => { setSelectedCategories(selectedItems); }}></Multiselect>
        <TextField
                    sx={{ m: 1}}
                    label="Internal Description"
                    placeholder="Internal Description"
                    value={item.internalDescription}
                    multiline
                    rows={3}
                    fullWidth
                    onChange={(event:any) => setItem((prevState:any) => ({
                      ...prevState,
                        internalDescription: event.target.value,
                    }))}
                />
                      <TextField
                    sx={{ m: 1}}
                    label="Public Description"
                    placeholder="Public Description"
                    value={item.publicDescription}
                    multiline
                    rows={3}
                    fullWidth
                    onChange={(event:any) => setItem((prevState:any) => ({
                      ...prevState,
                        publicDescription: event.target.value,
                    }))}
                />
          </TabPanel>

          <TabPanel value="2">

          
          <DataGrid
        rows={trackingTableData}
        columns={columnsTracking}
        pageSize={13}
        rowsPerPageOptions={[13]}
        disableSelectionOnClick
        autoHeight={true}
        experimentalFeatures={{ newEditingApi: true }}
      />

          </TabPanel>
        </TabContext>

        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleSave}>Save</Button>
          <Button variant="contained" onClick={handleSaveAndNew}>Save and new</Button>          
          <Button disabled={item.id==='new'} onClick={ () => { setIsConfirmDialogOpen(true) } }>Delete</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        open={isConfirmDialogOpen}
        title="Confirmation"
        message="Are you sure you want to delete this item?"
        onConfirm={handleDelete}
        onCancel={() => { setIsConfirmDialogOpen(false)}}
      />

    </Box>
  );
}